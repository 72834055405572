import { createAsyncThunk } from "@reduxjs/toolkit";

import { getReportWishlist, getWishlist } from "../../../services/analyticsService";

import { SLICE_NAME } from "./types";
import { generateFile } from "../../../utils/generateFile";

export const getWishlistThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetWishlist`,
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const wishlist = await getWishlist(params);

      return wishlist.data;
    } catch (err) {
      if (!err?.status) {
        return fulfillWithValue([]);
      }
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const createReportWishListThunk = createAsyncThunk(
  `${SLICE_NAME}/createReportWishList`,
  async (params, { rejectWithValue }) => {
    try {
      const result = await getReportWishlist(params);

      generateFile(result.data.data);

      return result.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
