export const REVIEW_MODERATION_STATUS = {
  NEW: "NEW",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

const REVIEW_MODERATION_STATUS_TAB = [REVIEW_MODERATION_STATUS.NEW];

export const REVIEW_MODERATION_STATUS_TEXT = {
  [REVIEW_MODERATION_STATUS.NEW]: "Новый отзыв",
  [REVIEW_MODERATION_STATUS.REJECTED]: "Отзыв скрыт",
  [REVIEW_MODERATION_STATUS.APPROVED]: "Отзыв виден всем пользователям",
};

export const REVIEW_MODERATION_STATUS_ITEM = [
  { id: 0, label: REVIEW_MODERATION_STATUS_TEXT.NEW, value: REVIEW_MODERATION_STATUS.NEW, position: 0 },
  { id: 1, label: REVIEW_MODERATION_STATUS_TEXT.APPROVED, value: REVIEW_MODERATION_STATUS.APPROVED, position: 1 },
  { id: 2, label: REVIEW_MODERATION_STATUS_TEXT.REJECTED, value: REVIEW_MODERATION_STATUS.REJECTED, position: 2 },
];

export const TheadColumns = [
  { name: "Товар", width: 20 },
  { name: "Отзыв", width: 50 },
  { name: "Пользователь", width: 15 },
  { name: "Оценка товара", width: 5 },
  { name: "Статус отзыва", width: 10 },
];
