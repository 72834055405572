import { Box, Flex, IconButton, Image, Input, InputGroup, InputRightElement, Text, theme } from "@chakra-ui/react";
import { ReactComponent as LoadFileIcon } from "../../assets/svg/LoadFileIcon.svg";

import React, { useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../../assets/icon/DeleteIcon";
import { UploadInput } from "../../components/UploadInput/UploadInput";
import BrandProductsSelect from "../../components/BrandProductsSelect";
import { TEXT_STYLE } from "./style";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

const SlideBox = ({
  slide,
  errors,
  values,
  handleDeleteSlideImage,
  handleSlideSelectFile,
  addProductToSlide,
  removeProductFromSlide,
  setSelectCurrentId,
  handleButtonText,
  setError,
  index,
}) => {
  const slideFileSelector = useRef();
  const [selectProduct, setSelectProduct] = useState([]);

  useEffect(() => {
    if (slide?.button?.product) {
      setSelectProduct([slide.button.product]);
    }
  }, []);

  const onAddProduct = (product) => {
    addProductToSlide(slide.id, product.id);
    setSelectProduct([product]);
  };

  const onButtonTextChange = (slideId, productId, text) => {
    handleButtonText(slideId, productId, text);
  };

  const onRemoveProduct = (slideId, productId) => {
    removeProductFromSlide(slideId, productId);
    setSelectProduct([]);
  };

  return (
    <Box
      style={{ boxShadow: "inset 0px 0px 3px 3px rgba(219,219,219,0.5)", padding: 15, borderRadius: 20, marginTop: 20 }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <Text>Слайд № {index + 1}</Text>

        {values.slides.length > 1 && (
          <Box style={{ display: "flex" }}>
            <IconButton
              bgColor="#FFFFFF"
              icon={<DeleteIcon isActive />}
              onClick={() => handleDeleteSlideImage(slide.id)}
              aria-label="delete"
            />
          </Box>
        )}
      </Box>

      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ display: "flex", flexDirection: "column", gap: "24px", width: "600px" }}>
          <FormControlWithError
            hasError={!!errors?.slides?.[index]?.contentId}
            errorText={errors?.slides?.[index]?.contentId}
          >
            <InputGroup>
              <Flex
                sx={{
                  position: "relative",
                  width: "100%",
                  backgroundColor: "#F8F8FA",
                  flexDirection: "row",
                  padding: 2,
                  borderRadius: theme.radii.md,
                  border: `1px solid ${theme.colors.transparent}`,
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxShadow: theme.shadows.xs,
                }}
              >
                {slide.contentId ? (
                  <Flex
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={slide.number}
                  >
                    <Image objectFit="cover" w="80px" height="80px" p="0" src={slide.contentUrl} />
                    <a rel="noreferrer" target="_blank" href={slide.url} style={TEXT_STYLE}>
                      {slide.contentId}
                    </a>
                  </Flex>
                ) : (
                  <Text>Добавить слайд</Text>
                )}
                <InputRightElement sx={{ alignSelf: "center", top: "auto" }}>
                  <Box onClick={() => setSelectCurrentId(slide.id)}>
                    <UploadInput
                      ref={slideFileSelector}
                      formats={[".jpg", ".jpeg", ".png"]}
                      maxFileSize={500}
                      onChangeFile={(file) => handleSlideSelectFile(file)}
                      reset={true}
                      setError={(errorText) => setError(`slides.[${index}].contentId`, errorText)}
                    />
                    <IconButton
                      height="14px"
                      w="14px"
                      icon={<LoadFileIcon />}
                      variant="unstyled"
                      aria-label="Load icon"
                      onClick={() => slideFileSelector.current.click()}
                    />
                  </Box>
                </InputRightElement>
              </Flex>
            </InputGroup>
          </FormControlWithError>
          <Box style={{ marginBottom: "60px" }}>
            <BrandProductsSelect
              isSearchAll
              products={selectProduct}
              onAdd={(product) => {
                onAddProduct(product);
              }}
              onDelete={() => {
                onRemoveProduct(slide.id, slide?.button?.productId);
              }}
              error={errors.product && [errors.product]}
            />
          </Box>
          {slide?.button?.productId && (
            <FormControlWithError
              hasError={!!errors?.slides?.[index]?.button?.text}
              errorText={errors?.slides?.[index]?.button?.text}
            >
              <Text>Текст кнопки</Text>
              <Input
                autoComplete="off"
                name="text"
                value={slide.button.text}
                onChange={(e) => onButtonTextChange(slide.id, slide.button.productId, e.target.value)}
              />
            </FormControlWithError>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SlideBox;
