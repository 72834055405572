import { array, object, string } from "yup";

export const STATUS_NAMES = {
  DRAFT: {
    name: "Не опубликован",
    color: "#E8FFEE",
  },
  PUBLISHED: {
    name: "Публикуется",
    color: "#E1FFFE",
  },
  ARCHIVED: {
    name: "Завершен",
    color: "#FFF8D7",
  },
};

export const ROLE_NAMES = [
  { value: "ALL", label: "Все" },
  { value: "SPECIALIST", label: "Специалисты" },
  { value: "RETAIL", label: "Розничные покупатели" },
];

export const STORY_VALIDATION_SCHEME = object({
  title: string().required('Поле "Название" обязательное').trim("Пробелы до/после названия недопустимы").strict(),
  thumbnailId: string().required('Поле "Миниатюра" обязательное'),
  dateRange: array()
    .nullable()
    .required('Поле "Время жизнеспособности" обязательное')
    .test("dateRange", "Некорректное время жизнеспособности", (dateRange) => {
      return dateRange[0] !== null && dateRange[1] !== null;
    }),
  startTime: string().required('Поле "Время жизнеспособности" обязательное'),
  endTime: string().required('Поле "Время жизнеспособности" обязательное'),
  allowedRole: object().nullable().required('Поле "Кто может просматривать" обязательное'),
  slides: array()
    .nullable()
    .max(10, "Максимум 10 слайдов")
    .min(1, 'Поле "Слайды" обязательное')
    .of(
      object().shape({
        contentId: string().trim().required('Поле "Слайд" обязательное'),
        button: object()
          .nullable()
          .when("$slides", {
            is: (value) => value && value.length > 0,
            then: object().shape({
              text: string().trim().required('Поле "Текст кнопки" обязательное'),
            }),
            otherwise: object().nullable(),
          }),
      })
    ),
});

export const DECLENSION_OF_HOURS = ["час", "часа", "часов"];
