import { requestAxios } from "../api/axiosRequest";

const notificationEndPoint = "/social-notification";

export const getNotifications = async ({ limit, page }) => {
  return requestAxios.get(`${notificationEndPoint}`, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
    },
  });
};

export const addNotification = async (payload) => {
  return requestAxios.post(`${notificationEndPoint}`, payload);
};

export const deleteNotification = async (id) => {
  return requestAxios.delete(`${notificationEndPoint}/${id}`);
};

export const editNotification = async ({ id, values }) => {
  return requestAxios.patch(`${notificationEndPoint}/${id}`, values);
};

export const getOneNotification = async (id) => {
  return requestAxios.get(`${notificationEndPoint}/${id}`);
};
