import TemplateDefaultContainer from "../../components/common/TemplateDefaultContainer";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteNotificationsToast } from "./store/GreenApiNotifications.slice";
import { GreenApiNotificationsTable } from "./components/GreenApiNotificationsTable";
import { useCustomToast } from "../../hooks/useCustomToast";
import { notificationsToast } from "./store/GreenApiNotifications.selectors";
import useUpdateEffect from "../../hooks/useUpdateEffect";

export const GreenApiNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notificationsToastInfo = useSelector(notificationsToast());

  const { pushToast } = useCustomToast();

  const cbToast = useCallback(() => {
    if (notificationsToastInfo.view) {
      pushToast(notificationsToastInfo);
      dispatch(deleteNotificationsToast());
    }
  }, [notificationsToastInfo, pushToast, dispatch]);

  useUpdateEffect(() => {
    cbToast();
  }, [notificationsToastInfo, cbToast]);

  return (
    <TemplateDefaultContainer handleCreateEntity={() => navigate("create")} textForAddButton="Добавить уведомление">
      <GreenApiNotificationsTable />
    </TemplateDefaultContainer>
  );
};
