import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import Select from "react-select";

import { useCustomToast } from "../../hooks/useCustomToast";
import { ROW_STYLE } from "../Products/constants";
import { Drawer } from "../../components/Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import { getOneReviewThunk } from "./store/Reviews.thunk";
import { getSelectReview } from "./store/Reviews.selectors";
import { StarIcon } from "../../assets/icon/StarIcon";
import DescriptionTextarea from "../../components/DescriptionTextarea/DescriptionTextarea";
import { REVIEW_MODERATION_STATUS_ITEM, REVIEW_MODERATION_STATUS_TEXT } from "./constants";
import { CUSTOM_STYLES_SELECTOR } from "../../common/constants";

const DrawerReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [newStatus, setNewStatus] = useState(null);
  const { detail: review, loadingState } = useSelector(getSelectReview());
  const handleClose = () => navigate(-1);
  const { text, mark, product, user, moderationStatus, updatedAt } = review;

  useEffect(() => {
    dispatch(getOneReviewThunk(id));
  }, [id]);

  const { pushToast } = useCustomToast();

  const handleChangeStatus = (item) => {
    setNewStatus(item.value);
  };
  const hanldeSaveReview = () => {
    if (newStatus) console.log(newStatus);
    handleClose();
  };
  return (
    <Drawer isOpen handleClose={handleClose} handleSave={hanldeSaveReview} isLoading={loadingState}>
      <Flex flexDirection="column" gap="4">
        <Flex boxSize="full" direction="column" sx={ROW_STYLE} gap="3">
          <Box>
            <Text>Название товара </Text>
            <Input value={product.name} isReadOnly />
          </Box>
          <Box>
            <Text>Бренд</Text>
            <Input value="бренд" isReadOnly />
          </Box>
          <Box>
            <Text>Артикул</Text>
            <Input value="codeFrom1C" isReadOnly />
          </Box>
        </Flex>

        <Flex boxSize="full" direction="column" sx={ROW_STYLE} gap="3">
          <Box>
            <Text>Отзыв</Text>
            <DescriptionTextarea style={{ minHeight: "150" }} resize="none" value={text} isReadOnly />
          </Box>
          <Flex boxSize="full" justifyContent="space-between">
            <Flex gap="2" sx={ROW_STYLE}>
              <Text>Оценка</Text>
              <Text>{mark}</Text>
              <Flex flexDirection="row" alignItems="center" gap="1">
                {[...new Array(mark)].map((_, i) => (
                  <StarIcon key={i} isActive />
                ))}
              </Flex>
            </Flex>
            <Flex gap="2" sx={ROW_STYLE}>
              <Text>Последнее изменение:</Text>
              <Text> 11.12.2023</Text>
            </Flex>
          </Flex>
          <Box>
            <Text>Статус отзыва</Text>
            <Select
              placeholder="Выберите назначение"
              defaultValue={{ value: 0, label: REVIEW_MODERATION_STATUS_TEXT[moderationStatus] }}
              onChange={handleChangeStatus}
              options={REVIEW_MODERATION_STATUS_ITEM}
              styles={CUSTOM_STYLES_SELECTOR}
            />
          </Box>
        </Flex>

        <Flex boxSize="full" direction="column" sx={ROW_STYLE} gap="3">
          <Box>
            <Text>Имя пользователя</Text>
            <Input resize="none" value={user.name} isReadOnly />
          </Box>

          <Flex gap="2">
            <Box flex="1">
              <Text>Email пользователя</Text>
              <Input resize="none" value={user.mail} isReadOnly />
            </Box>

            <Box flex="1">
              <Text>Номер пользователя</Text>
              <Input resize="none" value={user.mail} isReadOnly />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default DrawerReview;
