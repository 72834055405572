import { USER_ROLES } from "../../common/constants";
import { GET_ENUMS, GET_PARAMS } from "../../hooks/useSearchModal";
import ROUTES from "../../routes/routes";

export const settingSubLink = [
  {
    text: "Настройки кешбэка",
    path: ROUTES.SETTINGS_CASHBACK,
  },
  {
    text: "Настройки промоакций",
    path: ROUTES.SETTINGS_PROMOTION,
  },
];

export const settingSubLinkAdmin = [
  {
    text: "Настройки историй",
    path: ROUTES.SETTINGS_STORIES,
  },
  {
    text: "Настройки кешбэка",
    path: ROUTES.SETTINGS_CASHBACK,
  },
  {
    text: "Настройки промоакций",
    path: ROUTES.SETTINGS_PROMOTION,
  },
  {
    text: "Сотрудники",
    path: ROUTES.SETTINGS_EMPLOYEES,
  },
  {
    text: "Push Уведомления",
    path: ROUTES.SETTINGS_NOTIFICATIONS,
  },
  {
    text: "Api Green уведомления",
    path: ROUTES.SETTINGS_API_GREEN_NOTIFICATIONS,
  },
];

export const settingSubLinkManager = [
  {
    text: "Моя карточка",
    path: (userId) => `${ROUTES.SETTINGS_MY_CARD}/${userId}`,
  },
  {
    text: "Смена пароля",
    path: (userId) =>
      `${ROUTES.SETTINGS}?${GET_PARAMS.modal}=${GET_ENUMS.modals.changePassword}&${GET_PARAMS.modalId}=${userId}`,
  },
];

export const settingSubLinks = { [USER_ROLES.ADMIN]: settingSubLinkAdmin, [USER_ROLES.MANAGER]: settingSubLinkManager };
