export const MODAL_Z_INDEX = "100";
export const POPUP_Z_INDEX = "666";
export const AUTOCOMPLETE_Z_INDEX = "666";
export const TABLE_HEAD_Z_INDEX = "1";
export const DRAWER_Z_INDEX = "100";
export const MAX_LENGTH_TAGS = 120;
export const MAX_WIDTH_PROTOCOL = "320px";
export const BORDER_STYLE_PURPURE = "1px solid #8a3597";
export const CROSS_ICON_Z_INDEX = "1";
export const HEIGHT_TABLE_ROW = "68px";
export const BORDER_DEFAULT_COLOR = "#D0D3DA";
export const BORDER_ERROR_COLOR = "#E53E3E";
export const DEFAULT_BORDER_STYLE = "1px solid #e0e5ea";
export const MAX_HEIGHT_BODY = "calc(100% - 48px)";
export const DRAWER_HEADER_STYLE = {
  padding: "22px 25px",
  borderBottom: "1px solid #737680",
  marginBottom: "18px",
};

export const getNoOptionText = () => "Нет вариантов для добавления";

export const TITLE_TEXT_STYLE = {
  fontWeight: "500",
  lineHeight: "24px",
  fontSize: "14px",
  color: "#37393D",
};

export const LABLE_STYLE = {
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "24px",
  color: "#37393D",
};

export const TEXT_STYLE = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#37393D",
};

export const HINT_STYLE = {
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#737680",
};

export const TEXT_OVERFLOW_STYLE = {
  maxWidth: "100px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const BODY_CELL_STYLES = {
  color: "#434652",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "400",
};

export const NAME_STYLES = {
  padding: "10px",
  maxHeight: "150px",
  overflowY: "scroll",
  color: "#37393D",
  marginBottom: "32px",
};

export const PLACEHOLDER_STYLE = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#737680",
};

export const ERROR_MESSAGE_STYLE = {
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#ff063a",
};

export const TABLE_HEAD_STYLE = {
  backgroundColor: "#EBECEF",
  position: "sticky",
  top: "0",
  zIndex: TABLE_HEAD_Z_INDEX,
};

export const telMask = "+7(999)-999-99-99";

export const WEEK_DAYS = {
  mon: "Понедельник",
  tue: "Вторник",
  wed: "Среда",
  thu: "Четверг",
  fri: "Пятница",
  sat: "Суббота",
  sun: "Воскресенье",
};

export const DAYS = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const CUSTOM_STYLES_SELECTOR = {
  control: (styles, { isFocused, menuIsOpen }) => ({
    ...styles,
    border: isFocused && menuIsOpen ? "1px solid #8a3597" : DEFAULT_BORDER_STYLE,
    outline: "none",
    boxShadow: isFocused && menuIsOpen ? "0 0 0 1px #8a3597" : "none",
    backgroundColor: "white",
    borderRadius: "6px",
    minHeight: "32px",
    ...TEXT_STYLE,
    "&:hover": {
      borderColor: "none",
    },
  }),
  multiValueRemove: (styles) => {
    return {
      ...styles,
      backgroundColor: "none",
      color: "#718096",
      "&:hover": {
        backgroundColor: "#F7ECF8",
        color: "black",
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#F7ECF8",
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 4,
    };
  },
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#F7ECF8" : null,
      color: "black",
      zIndex: "10",

      cursor: "pointer",
      ...TEXT_STYLE,
      "&:hover": {
        backgroundColor: "#F7ECF8",
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      padding: "0 8px",
      justifyItems: "start",
    };
  },
  input: (styles) => {
    return {
      ...styles,
      margin: 0,
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontSize: "14px",
    };
  },
};

export const SELECTED_TAB_STYLES = { color: "dark.100", borderColor: "purple.500" };

export const HEAD_CELL_STYLES = {
  textTransform: "none",
  color: "#737680",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: "500",
  fontFamily: "'Inter', sans-serif",
};

export const MAX_MARGIN = 100;

export const TheadColumns = [
  { name: "ФИ Организация", width: 25 },
  { name: "Почта", width: 25 },
  { name: "Телефон", width: 25 },
  { name: "Дата и время последнего входа", width: 25 },
];

export const userRequestTheadColumns = [
  { name: "ФИ Организация", width: 33 },
  { name: "Почта", width: 33 },
  { name: "Телефон", width: 33 },
];

export const DEFAULT_DATE_FORMAT = "dd.MM.yyyy"; // 02.12.2024 default day.mount.year date format
export const DEFAULT_TIME_FORMAT = "HH:mm";

export const DATE_FORMAT_API = "yyyy-MM-dd";
export const DATE_FORMAT_WITH_TIME = "dd.MM.yyyy HH:mm";

export const DEFAULT_PROMPT_TEXT = "Если вы закроете окно, изменения утеряются, закрыть окно?";

export const USER_ROLES = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  ANALYTIC: "ANALYTIC",
};

export const HUMAN_READABLE_USER_ROLES = {
  ADMIN: "Администратор",
  MANAGER: "Менеджер",
  ANALYTIC: "Аналитик",
};

export const USER_ROLES_ACCESS = {
  ADMIN_ONLY: [USER_ROLES.ADMIN],
  ADMIN_AND_MANAGER: [USER_ROLES.ADMIN, USER_ROLES.MANAGER],
  ALL: [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.ANALYTIC],
};

export const USER_ROLES_OPTIONS = [
  { value: USER_ROLES.ADMIN, label: HUMAN_READABLE_USER_ROLES.ADMIN },
  { value: USER_ROLES.MANAGER, label: HUMAN_READABLE_USER_ROLES.MANAGER },
  { value: USER_ROLES.ANALYTIC, label: HUMAN_READABLE_USER_ROLES.ANALYTIC },
];
