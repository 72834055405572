import React from "react";

const ApproveIcon = ({ isActive }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        fill={isActive ? "#C06ECC" : "#99939f"}
        d="m13.11 5.72-.57 2.89c-.12.59.04 1.2.42 1.66s.94.73 1.54.73H20v1.08L17.43 18H9.34c-.18 0-.34-.16-.34-.34V9.82zM14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.83C7 18.95 8.05 20 9.34 20h8.1c.71 0 1.36-.37 1.72-.97l2.67-6.15c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22zM4 9H2v11h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1"
      />
    </svg>
  );
};

export default ApproveIcon;
