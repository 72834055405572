import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsThunk } from "../store/GreenApiNotifications.thunk";
import { Box, Flex, useUpdateEffect } from "@chakra-ui/react";
import { Pagination } from "../../../components/Pagination";
import { usePagination } from "../../../hooks/usePagination";
import {
  getNotifications,
  notificationsIsLoading,
  notificationsIsUpdated,
  getNotificationsCount,
} from "../store/GreenApiNotifications.selectors";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import { TableRow } from "./TableRow";

const notificationRow = (notification) => (
  <TableRow notification={notification} key={`notification-${notification.id}`} />
);

const TheadColumns = [
  { name: "Уведомление", width: 33 },
  { name: "Дата / время", width: 33 },
  { name: "Целевая аудитория", width: 33 },
];

export const GreenApiNotificationsTable = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(getNotifications());
  const total = useSelector(getNotificationsCount());

  const {
    isPageValid,
    isPrevDisabled,
    isNextDisabled,
    page,
    limit,
    totalPages,
    nextPage,
    prevPage,
    setPage,
    setLimit,
  } = usePagination({
    total,
  });

  useUpdateEffect(() => {
    if (total === 0 || isPageValid) {
      dispatch(getNotificationsThunk({ limit, page: total === 0 ? 1 : page }));
    }
  }, [limit, total, page, isPageValid, dispatch]);

  const isNotificationsLoading = useSelector(notificationsIsLoading());
  const isNeedNotificationsUpdated = useSelector(notificationsIsUpdated());

  const requestNotifications = useCallback(() => {
    dispatch(getNotificationsThunk({ limit, page: total === 0 ? 1 : page }));
  }, [dispatch]);

  useEffect(() => {
    requestNotifications();
  }, [requestNotifications]);

  useUpdateEffect(() => {
    if (isNeedNotificationsUpdated) {
      requestNotifications();
    }
  }, [isNeedNotificationsUpdated, requestNotifications]);

  return (
    <Box>
      <Flex justify="end" marginBottom="15px">
        <Pagination
          totalPages={totalPages}
          page={page}
          limit={limit}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
          onNextPage={nextPage}
          onPrevPage={prevPage}
          onChangePage={setPage}
          onLimitChange={setLimit}
        />
      </Flex>

      <TableConstructor
        isLoading={isNotificationsLoading}
        Row={notificationRow}
        theadItems={TheadColumns}
        items={notifications}
        notFoundText="Уведомлений пока не создано"
      />
    </Box>
  );
};
