import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer } from "../../components/Drawer/Drawer";
import { useSelector, useDispatch } from "react-redux";
import {
  addingNotification,
  getNotification,
  editingNotification,
  deletingNotification,
} from "../GreenApiNotifications/store/GreenApiNotifications.thunk";
import { clearEditingPushValues } from "../GreenApiNotifications/store/GreenApiNotifications.slice";
import {
  notificationIsLoading,
  getEditingNotification,
} from "../GreenApiNotifications/store/GreenApiNotifications.selectors";
import { FormikProvider, useFormik } from "formik";
import { FormPartsControl } from "./FormPartsControl";
import { NameAndTitleFormPart } from "./FormParts/NameAndTitleFormPart";
import { AudienceFormPart } from "./FormParts/AudienceFormPart";
import { DateTimeAndPreviewFormPart } from "./FormParts/DateTimeAndPreviewFormPart";
import { HOURS_AND_MINUTES_DATE_FORMAT, NOTIFICATION_VALIDATE_SCHEMA } from "./constants";
import { format } from "date-fns";
import { formikGreenApiPushValuesToApi } from "./helpers/formikPushValuesToApi";

const initialValues = {
  message: "",
  roles: [],
  warehouses: [],
  users: [],
  date: new Date(),
  time: "",
};

export const GreenApiNotificationsModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const editingNotificationValues = useSelector(getEditingNotification());
  const isNotificationLoading = useSelector(notificationIsLoading());

  const [activeFormPart, setActiveFormPart] = useState(1);

  const handleCloseModal = () => navigate(-1);

  const handleModalSubmit = (values, _formik, formIsEdit) => {
    if (formIsEdit) {
      const dateWithoutTime = new Date(values.date.getFullYear(), values.date.getMonth(), values.date.getDate());
      const valuesForRequest = formikGreenApiPushValuesToApi(values, dateWithoutTime);

      let currentRequest = null;
      if (id) {
        currentRequest = dispatch(editingNotification({ id, values: valuesForRequest }));
      } else {
        currentRequest = dispatch(addingNotification(valuesForRequest));
      }
      currentRequest.unwrap().then(() => {
        handleCloseModal();
      });
    } else {
      handleCloseModal();
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (formValues, formikBag) => handleModalSubmit(formValues, formikBag, formik.dirty),
    validationSchema: NOTIFICATION_VALIDATE_SCHEMA,
  });

  const { values, setFieldValue, handleSubmit } = formik;

  const handleDropdownChange = (arrayName, checkedArray, event) => {
    const { option } = event;

    if (option.value.payload === "all") {
      return;
    }

    if (!option.value.isChecked) {
      const checkedArrayWithoutAll = checkedArray.filter((item) => item.value.payload !== "all");
      setFieldValue(
        arrayName,
        checkedArrayWithoutAll.map((checkedElement) => checkedElement.value.payload)
      );

      return;
    }

    setFieldValue(
      arrayName,
      checkedArray.reduce((prev, currentValue) => {
        if (currentValue.value.payload !== option.value.payload && currentValue.value.payload !== "all") {
          return [...prev, currentValue.value.payload];
        }

        return prev;
      }, [])
    );
  };

  useEffect(() => {
    dispatch(clearEditingPushValues());
    if (id) {
      dispatch(getNotification(id));
    }
  }, []);

  useEffect(() => {
    if (editingNotificationValues && id === editingNotificationValues.id) {
      formik.resetForm({
        values: {
          ...editingNotificationValues,
          warehouses: editingNotificationValues.warehouses.map((warehouse) => warehouse.id),
          date: new Date(Date.parse(editingNotificationValues.datetime)),
          time: format(Date.parse(editingNotificationValues.datetime), HOURS_AND_MINUTES_DATE_FORMAT),
        },
      });
    }
  }, [editingNotificationValues, id]);

  const isFormBlockedForEditing = editingNotificationValues?.status === "SHEDULED";

  const handleDeleteNotification = () => {
    dispatch(deletingNotification(id));
  };

  const handleUserDropDownChange = (user, booleanAction) => {
    let arrCopy = [];
    if (booleanAction) {
      arrCopy = [user, ...values.users];
      setFieldValue("users", arrCopy);

      return;
    }

    arrCopy = values.users.filter((arrayUser) => arrayUser.id !== user.id);
    setFieldValue("users", arrCopy);
  };

  const FORM_STATES = [
    {
      title: "Уведомления",
      isReady: values.message?.length !== 0,
      isSelected: activeFormPart === 1,
      component: <NameAndTitleFormPart isFormBlockedForEditing={isFormBlockedForEditing} />,
      key: 1,
    },
    {
      title: "Целевая аудитория",
      isFormBlockedForEditing,
      isReady: !!(values.roles?.length || values.warehouses.length || values.users.length),
      isSelected: activeFormPart === 2,
      component: (
        <AudienceFormPart
          handleDropdownChange={handleDropdownChange}
          handleUserDropDownChange={handleUserDropDownChange}
          isFormBlockedForEditing={isFormBlockedForEditing}
        />
      ),
      key: 2,
    },
    {
      title: "Время и дата",
      isReady:
        !!(values.roles.length || values.warehouses.length || values.users.length) &&
        values.message.length !== 0 &&
        values.time &&
        values.date,
      isSelected: activeFormPart === 3,
      component: <DateTimeAndPreviewFormPart isFormBlockedForEditing={isFormBlockedForEditing} />,
      key: 3,
    },
  ];

  const nextStepButtonClick = (key) => {
    setActiveFormPart(key + 1);
  };

  const previousStepButtonClick = (key) => {
    setActiveFormPart(key);
  };

  return (
    <FormikProvider value={formik}>
      <Drawer
        isLoading={isNotificationLoading}
        buttonCancelText={id ? "Удалить" : "Отменить"}
        isOpen
        handleClose={handleCloseModal}
        handleCancelClick={id ? handleDeleteNotification : handleCloseModal}
        handleSave={handleSubmit}
        isSaveOptionDisabled={!FORM_STATES.at(-1).isReady}
      >
        {FORM_STATES.map((formPart, index) => (
          <FormPartsControl
            key={formPart.key}
            renderComponentForm={formPart.component}
            isActive={formPart.isSelected}
            isReady={formPart.isReady}
            formPartNumber={formPart.key}
            stepHeader={formPart.title}
            previousStepButtonClick={previousStepButtonClick}
            nextStepButtonClick={nextStepButtonClick}
            isLastPart={index === FORM_STATES.length - 1}
          />
        ))}
      </Drawer>
    </FormikProvider>
  );
};
