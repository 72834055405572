import { requestAxios } from "../api/axiosRequest";

export const getReportWishlist = (params) => {
  return requestAxios.get(`/reports/wishlists`, { params });
};

export const getReviews = (params) => {
  return requestAxios.get("/reviews", { params });
};

export const getOneReview = (id) => {
  // return requestAxios.get(`/review/${id}`);
};
export const editReviewStatus = (id, status) => {
  return requestAxios.patch(`/reviews/${id}`, status);
};
