import { Td, Tr, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { parseISO, format } from "date-fns";
import getDeliveryType from "../../../utils/getDeliveryType";
import getOrderStatusIcon from "../../../utils/getOrderStatusIcon";
import PayStatusIcon from "./PayStatusIcon";
import { WITHOUT_TIME_DATE_FORMAT } from "../constants";
import { DEFAULT_TIME_FORMAT } from "../../../common/constants";
import { getDeliveryIcon } from "../../Orders/utils/generatePicPupItems";

const OrderTableRow = ({
  id,
  orderNumber,
  date,
  aquiredBonus,
  usedBonus,
  total,
  isPayed,
  paymentType,
  orderActivity,
  delivery,
}) => {
  const navigate = useNavigate();
  const renderDeliveryType = getDeliveryType(delivery.deliveryType);
  const orderStatusIcon = getOrderStatusIcon(orderActivity);
  const payStatusIcon = <PayStatusIcon isPayed={isPayed} paymentType={paymentType} />;

  return (
    <Tr cursor="pointer" onClick={() => navigate(`order/${id}`)}>
      <Td>{orderNumber}</Td>
      <Td>
        {format(parseISO(date), WITHOUT_TIME_DATE_FORMAT)} <br /> {format(parseISO(date), DEFAULT_TIME_FORMAT)}
      </Td>
      <Td>
        <Flex gap="2" alignItems="center">
          {getDeliveryIcon(delivery.deliveryService)}
          {renderDeliveryType}
        </Flex>
      </Td>
      <Td>{orderStatusIcon}</Td>
      <Td>{payStatusIcon}</Td>
      <Td>
        <Flex textAlign="center">
          <Flex color="yellow" textShadow="0px 0px 1px black" fontWeight={1000} fontSize="28px">
            +
          </Flex>
          <Text width="50px" align="start">
            {aquiredBonus}
          </Text>
          <Flex color="yellow" fontWeight={1000} fontSize="28px" textShadow="0px 0px 1px black">
            -
          </Flex>
          <Text>{usedBonus}</Text>
        </Flex>
      </Td>
      <Td>{total}</Td>
    </Tr>
  );
};

export default OrderTableRow;
