import { formatISO, add } from "date-fns";

export const formikPushValuesToApi = (values, dateWithoutTime) => {
  const [hours, minutes] = values.time?.split(":") ?? [];

  return {
    message: values.message,
    roles: values.roles,
    warehouseIds: values.warehouses,
    datetime: formatISO(
      add(dateWithoutTime, {
        hours,
        minutes,
      })
    ),
    userIds: values.users.map((user) => user.id),
  };
};
export const formikGreenApiPushValuesToApi = (values, dateWithoutTime) => {
  const [hours, minutes] = values.time?.split(":") ?? [];

  return {
    message: values.message,
    roles: values.roles,
    warehouseIds: values.warehouses,
    datetime: formatISO(
      add(dateWithoutTime, {
        hours,
        minutes,
      })
    ),
    userIds: values.users.map((user) => user.id),
  };
};
