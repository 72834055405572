import * as Yup from "yup";
import { add, isBefore, startOfDay } from "date-fns";

export const userRolesForNotifications = {
  RETAIL: { value: "RETAIL", label: "Розница" },
  SPECIALIST: { value: "SPECIALIST", label: "Специалист" },
};

export const FORM_PARTS_NAMES = {
  textAndTitle: 1,
  audience: 2,
  dateTimeAndPreview: 3,
};

export const PREVIEW_DATE_FORMAT = "d.LL.y"; // 19.12.2023

export const HOURS_AND_MINUTES_DATE_FORMAT = "HH:mm"; // 16:11

const startOfToday = startOfDay(Date.now());

export const NOTIFICATION_VALIDATE_SCHEMA = Yup.object().shape({
  message: Yup.string()
    .required('Поле "Текст уведомления" обязательное')
    .min(1, "Не меньше 1 символа")
    .max(1000, "Максимальное количество символов 1000"),
  roles: Yup.array().of(Yup.string()),
  warehouses: Yup.array().of(Yup.string()),
  users: Yup.array().of(Yup.object()),
  date: Yup.date()
    .required('Поле "Дата" обязательное')
    .min(startOfToday, 'Поля "Дата" и "Время" не могут содержать прошедшее время'),
  time: Yup.string()
    .required('Поле "Время" обязательное')
    .test(
      "time validation",
      "если в date выбран сегодняшний день, то минимальное значение time - текущий час и минута",
      (value, schema) => {
        const { parent, createError } = schema;
        const { date } = parent;
        const [hours, minutes] = value.split(":");

        const valueDate = add(startOfDay(date), {
          hours,
          minutes,
        });

        if (isBefore(valueDate, new Date())) {
          return createError({
            path: "time",
            message: 'Поля "Дата" и "Время" не могут содержать прошедшее время',
          });
        }

        return true;
      }
    ),
});

export const COLORS = {
  DISABLED_GRAY: "#B5B9C5",
  ACTIVE_PURPLE: "#BF67D5",
};
