/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";
import { changeReviewStatusThunk, getReviewsThunk } from "./Reviews.thunk";
import { createReportOrdersThunk } from "../../Orders/store/Orders.thunk";

const initialToast = { view: false, title: "", description: "", status: "" };

const initialState = {
  toast: initialToast,
  loadingState: LoadingState.IDLE,
  reviews: [],
  loadingStateReport: LoadingState.IDLE,
  selectedReview: { detail: {}, loadingState: LoadingState.IDLE },
};

export const {
  reducer,
  actions: { deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviewsThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getReviewsThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: payload.message,
          status: "error",
        };

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getReviewsThunk.fulfilled, (state, { payload }) => {
        state.loadingState = LoadingState.FULFILLED;
        state.reviews = payload;
      })
      .addCase(changeReviewStatusThunk.pending, (state) => {
        state.selectedReview.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(changeReviewStatusThunk.fulfilled, (state, action) => {
        state.selectedReview.loadingState = LoadingState.FULFILLED;
      })
      .addCase(changeReviewStatusThunk.rejected, (state, action) => {
        state.selectedReview.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })

      .addCase(createReportOrdersThunk.fulfilled, (state) => {
        state.loadingStateReport = LoadingState.FULFILLED;
      })
      .addCase(createReportOrdersThunk.rejected, (state) => {
        state.loadingStateReport = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "Что-то пошло не так, повторите попытку позже",
          status: "error",
        };
      })
      .addCase(createReportOrdersThunk.pending, (state) => {
        state.loadingStateReport = LoadingState.PENDING;
        state.toast = initialToast;
      });
  },
});
