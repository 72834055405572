import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import LoginForm from "../pages/Login/Login";
import ResetPasswordForm from "../pages/ResetPassword/ResetPasswordForm";
import ForgotPasswordForm from "../pages/ResetPassword/ForgotPasswordForm";
import HomePage from "../pages/Homepage";
import PageNotFound from "../pages/404";

import Banners from "../pages/Banners";
import Brands from "../pages/Brands/Brands";
import Categories from "../pages/Categories";
import Cities from "../pages/Cities";
import Products from "../pages/Products/Products";
import Promocodes from "../pages/Promocodes";
import ProtocolCategries from "../pages/ProtocolCategories/ProtocolCategories";
import Protocols from "../pages/Protocols/Protocols";
import SeminarsPage from "../pages/Seminars";
import Users from "../pages/Users/Users";
import Orders from "../pages/Orders/Orders";
import DrawerOrder from "../pages/Orders/DrawerOrder";
import Clients from "../pages/Clients/Clients";
import CoursesPage from "../pages/Courses/CoursesPage";

import { routeGuard } from "./guards/routesGuard";
import ROUTES from "./routes";
import Settings from "../pages/Settings/Settings";
import CashbackSettings from "../pages/CashbackSettings";
import { Promotion } from "../pages/Promotion/Promotion";
import EditPromocodeModal from "../pages/Promocodes/EditModal";
import { CashbackModal } from "../pages/CashbackSettings/CashbackModal";
import DrawerCreateProtocol from "../pages/Protocols/DrawerCreateProtocol";
import EditBannerModal from "../pages/Banners/EditModal";
import { WishlistPage } from "../pages/Wishlists/WishlistPage";

import EditSeminarModalFeature from "../pages/Seminars/FutureSeminars/EditSeminarModal";
import EditSeminarModalHistory from "../pages/Seminars/SeminarsHistory/EditSeminarModal";
import CourseModal from "../pages/Courses/Components/CourseModal";
import DrawerUser from "../pages/Users/DrawerUser";
import WarehouseModal from "../pages/Cities/WarehouseModal";
import { checkIsRoleAdmin, checkIsRoleManager, checkIsRoleAnalytic } from "../utils/roles";
import { Employees } from "../pages/Employees/Employees";
import { Notifications } from "../pages/Notifications/Notifications";
import { NotificationModal } from "../pages/NotificationsModal/NotificationModal";
import { EmployeeModal } from "../pages/EmployeeModal/EmployeeModal";
import DrawerProduct from "../pages/Products/DrawerProduct";
import { SpecialistPromocodes } from "../pages/SpecialistPromocodes/SpecialistPromocodes";
import { ClientInfo } from "../pages/ClientInfo/ClientInfo";
import StoriesSettings from "../pages/StoriesSettings/StoriesSettings";
import { StoryModal } from "../pages/StoriesSettings/StoryModal";
import { ReviewsPage } from "../pages/Reviews/ReviewsPage";
import DrawerReview from "../pages/Reviews/DrawerReview";
import { GreenApiNotificationsModal } from "../pages/GreenApiNotificationsModal/GreenApiNotificationsModal";
import { GreenApiNotifications } from "../pages/GreenApiNotifications/GreenApiNotifications";

const generateModalWithRouting = (Component, additionalPath = "") => {
  const generatedPath = additionalPath && `${additionalPath}/`;

  return [
    { path: `${generatedPath}:id`, element: <Component /> },
    { path: `${generatedPath}create`, element: <Component /> },
  ];
};

const routes = (userIsAuth, role) => {
  if (checkIsRoleAdmin(role)) {
    return [
      {
        path: ROUTES.LOGIN,
        element: userIsAuth ? <Navigate to={ROUTES.PRODUCTS} /> : <LoginForm />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: userIsAuth ? <Navigate to={ROUTES.USERS} /> : <ForgotPasswordForm />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPasswordForm />,
      },
      {
        path: ROUTES.ROOT,
        element: routeGuard(<HomePage />),
      },
      {
        path: ROUTES.USERS,
        element: routeGuard(
          <>
            <Users />
            <Outlet />
          </>
        ),
        children: [{ path: ":id", element: <DrawerUser /> }],
      },
      {
        path: ROUTES.PRODUCTS,
        element: routeGuard(
          <>
            <Products />
            <Outlet />
          </>
        ),
        children: [{ path: ":id", element: <DrawerProduct /> }],
      },
      {
        path: ROUTES.CLIENTS,
        element: routeGuard(<Clients />),
      },
      {
        path: ROUTES.CLIENT_INFO,
        element: routeGuard(
          <>
            <ClientInfo />
            <Outlet />
          </>
        ),
        children: [{ path: "order/:orderId", element: <DrawerOrder /> }],
      },
      {
        path: ROUTES.SEMINARS,
        element: routeGuard(
          <>
            <SeminarsPage />
            <Outlet />
          </>
        ),
        children: [
          ...generateModalWithRouting(EditSeminarModalFeature, "feature"),
          ...generateModalWithRouting(EditSeminarModalHistory, "history"),
        ],
      },
      {
        path: ROUTES.COURSES,
        element: routeGuard(
          <>
            <CoursesPage />
            <Outlet />
          </>
        ),
        children: [...generateModalWithRouting(CourseModal)],
      },
      { path: ROUTES.NOTFOUND, element: <PageNotFound /> },
      { path: ROUTES.BRANDS, element: routeGuard(<Brands />) },
      {
        path: ROUTES.PROTOCOL_CATEGORIES,
        element: routeGuard(<ProtocolCategries />),
      },
      {
        path: ROUTES.ORDERS,
        element: routeGuard(
          <>
            <Orders />
            <Outlet />
          </>
        ),
        children: [{ path: ":orderId", element: <DrawerOrder /> }],
      },
      {
        path: ROUTES.WISHLIST,
        element: routeGuard(
          <>
            <WishlistPage />
            <Outlet />
          </>
        ),
        children: [{ path: ":id", element: <DrawerProduct /> }],
      },
      {
        path: ROUTES.CATEGORY,
        element: routeGuard(<Categories />),
      },
      {
        path: ROUTES.CITIES,
        element: routeGuard(
          <>
            <Outlet />
            <Cities />
          </>
        ),
        children: generateModalWithRouting(WarehouseModal),
      },
      {
        path: ROUTES.BANNERS,
        element: routeGuard(
          <>
            <Banners />
            <Outlet />
          </>
        ),
        children: generateModalWithRouting(EditBannerModal),
      },
      {
        path: ROUTES.PROTOCOLS,
        element: routeGuard(
          <>
            <Protocols />
            <Outlet />
          </>
        ),
        children: generateModalWithRouting(DrawerCreateProtocol),
      },
      {
        path: ROUTES.PROMOCODES,
        element: routeGuard(
          <>
            <Promocodes />
            <Outlet />
          </>
        ),
        children: generateModalWithRouting(EditPromocodeModal),
      },
      {
        path: ROUTES.SPECIALIST_PROMOCODES,
        element: routeGuard(
          <>
            <SpecialistPromocodes />
            <Outlet />
          </>
        ),
        children: generateModalWithRouting(EditPromocodeModal),
      },
      {
        path: ROUTES.SETTINGS,
        element: routeGuard(<Settings />),
      },
      {
        path: ROUTES.SETTINGS_CASHBACK,
        element: routeGuard(
          <>
            <CashbackSettings />
            <Outlet />
          </>
        ),
        children: generateModalWithRouting(CashbackModal),
      },
      {
        path: ROUTES.SETTINGS_PROMOTION,
        element: routeGuard(<Promotion />),
      },
      {
        path: ROUTES.SETTINGS_EMPLOYEES,
        element: routeGuard(
          <>
            <Outlet />
            <Employees />
          </>
        ),
        children: generateModalWithRouting(EmployeeModal),
      },
      {
        path: ROUTES.SETTINGS_STORIES,
        element: routeGuard(
          <>
            <StoriesSettings />
            <Outlet />
          </>
        ),
        children: generateModalWithRouting(StoryModal),
      },
      {
        path: ROUTES.REVIEW,
        element: routeGuard(
          <>
            <ReviewsPage />
            <Outlet />
          </>
        ),
        children: [{ path: ":id", element: <DrawerReview /> }],
      },

      {
        path: ROUTES.SETTINGS_NOTIFICATIONS,
        element: routeGuard(
          <>
            <Outlet />
            <Notifications />
          </>
        ),
        children: generateModalWithRouting(NotificationModal),
      },
      {
        path: ROUTES.SETTINGS_API_GREEN_NOTIFICATIONS,
        element: routeGuard(
          <>
            <Outlet />
            <GreenApiNotifications />
          </>
        ),
        children: generateModalWithRouting(GreenApiNotificationsModal),
      },
    ];
  }

  if (checkIsRoleManager(role)) {
    return [
      {
        path: ROUTES.LOGIN,
        element: userIsAuth ? <Navigate to={ROUTES.ORDERS} /> : <LoginForm />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: userIsAuth ? <Navigate to={ROUTES.USERS} /> : <ForgotPasswordForm />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPasswordForm />,
      },
      {
        path: ROUTES.ROOT,
        element: routeGuard(<HomePage />),
      },
      {
        path: ROUTES.USERS,
        element: routeGuard(
          <>
            <Users />
            <Outlet />
          </>
        ),
        children: [{ path: ":id", element: <DrawerUser /> }],
      },
      {
        path: ROUTES.CLIENTS,
        element: routeGuard(<Clients />),
      },
      { path: ROUTES.NOTFOUND, element: <PageNotFound /> },
      {
        path: ROUTES.ORDERS,
        element: routeGuard(
          <>
            <Orders />
            <Outlet />
          </>
        ),
        children: [{ path: ":orderId", element: <DrawerOrder /> }],
      },
      {
        path: ROUTES.SETTINGS,
        element: routeGuard(
          <>
            <Outlet />
            <Settings />
          </>
        ),
        children: [
          {
            path: `${ROUTES.SETTINGS_MY_CARD}/:id`,
            element: routeGuard(<EmployeeModal />),
          },
        ],
      },
    ];
  }

  if (checkIsRoleAnalytic(role)) {
    return [
      {
        path: ROUTES.LOGIN,
        element: userIsAuth ? <Navigate to={ROUTES.ORDERS} /> : <LoginForm />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: userIsAuth ? <Navigate to={ROUTES.USERS} /> : <ForgotPasswordForm />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPasswordForm />,
      },
      {
        path: ROUTES.ROOT,
        element: routeGuard(<HomePage />),
      },
      {
        path: ROUTES.NOTFOUND,
        element: <PageNotFound />,
      },
      {
        path: ROUTES.ORDERS,
        element: routeGuard(
          <>
            <Orders />
            <Outlet />
          </>
        ),
        children: [{ path: ":orderId", element: <DrawerOrder /> }],
      },
      {
        path: ROUTES.SETTINGS,
        element: routeGuard(
          <>
            <Outlet />
            <Settings />
          </>
        ),
        children: [
          {
            path: `${ROUTES.SETTINGS_MY_CARD}/:id`,
            element: routeGuard(<EmployeeModal />),
          },
        ],
      },
    ];
  }

  if (checkIsRoleAnalytic(role)) {
    return [
      {
        path: ROUTES.LOGIN,
        element: userIsAuth ? <Navigate to={ROUTES.ORDERS} /> : <LoginForm />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: userIsAuth ? <Navigate to={ROUTES.USERS} /> : <ForgotPasswordForm />,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        element: <ResetPasswordForm />,
      },
      {
        path: ROUTES.ROOT,
        element: routeGuard(<HomePage />),
      },
      {
        path: ROUTES.NOTFOUND,
        element: <PageNotFound />,
      },
      {
        path: ROUTES.ORDERS,
        element: routeGuard(
          <>
            <Orders />
            <Outlet />
          </>
        ),
        children: [{ path: "/:orderId", element: <DrawerOrder /> }],
      },
      {
        path: ROUTES.SETTINGS,
        element: routeGuard(
          <>
            <Outlet />
            <Settings />
          </>
        ),
        children: [
          {
            path: `${ROUTES.SETTINGS_MY_CARD}/:id`,
            element: routeGuard(<EmployeeModal />),
          },
        ],
      },
    ];
  }

  return [
    {
      path: ROUTES.LOGIN,
      element: <LoginForm />,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgotPasswordForm />,
    },
    { path: "*", element: <Navigate to={ROUTES.LOGIN} /> },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPasswordForm />,
    },
  ];
};

export default routes;
