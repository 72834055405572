import { Fragment } from "react";
import { useFormikContext } from "formik";
import { Flex, Text, Box, Divider } from "@chakra-ui/react";
import { format, isToday } from "date-fns";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import TimePicker from "../../../components/TimePicker/TimePicker";
import { PREVIEW_DATE_FORMAT, userRolesForNotifications } from "../constants";
import { commasLogic } from "../../../utils/commasLogic";
import { FormControlWithError } from "../../../components/FormControlWithError/FormControlWithError";

export const DateTimeAndPreviewFormPart = ({ isFormBlockedForEditing }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const isTodaySelected = values.date ? isToday(values.date) : false;

  const isCurrentHour = Number(values.time?.split(":")[0]) === new Date().getHours();

  return (
    <>
      <Flex>
        <Box width="270px" marginRight="10px">
          <FormControlWithError title="Дата" hasError={errors.date} errorText={errors.date}>
            <DatePicker
              selected={values.date}
              onChange={(date) => setFieldValue("date", date)}
              isDisabled={isFormBlockedForEditing}
            />
          </FormControlWithError>
        </Box>

        <Box width="270px">
          <FormControlWithError title="Время" hasError={errors.time} errorText={errors.time}>
            <TimePicker
              value={values.time}
              minHours={isTodaySelected ? new Date().getHours() : 6}
              maxHours={22}
              minMinute={isCurrentHour && isTodaySelected ? new Date().getMinutes() : 0}
              stampMinutes={5}
              onChange={(time) => setFieldValue("time", time)}
              isDisabled={isFormBlockedForEditing}
            />
          </FormControlWithError>
        </Box>
      </Flex>

      <Box>
        <Divider my="30px" />
        <Text marginBottom="15px">Предпросмотр уведомления</Text>

        <Flex>
          <Text marginRight="60px" width="90px">
            Контент
          </Text>
          <Box>
            <Text>{values.message}</Text>
          </Box>
        </Flex>

        <Divider my="30px" />

        <Flex>
          <Text marginRight="60px" width="90px">
            Целевая аудитория
          </Text>

          <Box>
            <Text>
              {values.roles.map((role, index) =>
                commasLogic(userRolesForNotifications[role].label, index, values.roles.length - 1)
              )}
            </Text>
            <Text>
              {values.users.map((user, index) => (
                <Fragment key={user.id}>
                  {commasLogic(
                    `${user.lastName} ${user.name} ${user.secondName ?? ""}`,
                    index,
                    values.users.length - 1
                  )}
                </Fragment>
              ))}
            </Text>

            {values.warehouses.length > 0 && <Text>Склады({values.warehouses.length})</Text>}
          </Box>
        </Flex>

        <Divider my="30px" />

        <Flex>
          <Text marginRight="60px" width="90px">
            Дата и время
          </Text>

          <Box>
            <Text>
              {values.date ? format(values.date, PREVIEW_DATE_FORMAT) : ""} {values.time ? values.time : ""}
            </Text>
          </Box>
        </Flex>

        <Divider my="30px" />
      </Box>
    </>
  );
};
