import { Box, Flex, Text, Img } from "@chakra-ui/react";

import phoneImg from "../../../assets/png/android.png";
import filaraLogo from "../../../assets/png/filaraLogo.png";

export const PhonePushPreview = ({ message }) => {
  return (
    <Box backgroundImage={`url(${phoneImg})`} height="180px" bgColor="purple.50" borderRadius="5px">
      <Flex
        position="relative"
        top="80px"
        left="68px"
        backgroundColor="gray.200"
        width="270px"
        height="60px"
        borderRadius="8px"
        align="center"
        paddingLeft="15px"
      >
        <Img src={filaraLogo} height="40px" />

        <Box marginLeft="10px" width="180px" whiteSpace="nowrap" height="50px">
          <Box overflow="hidden" textOverflow="ellipsis">
            <Text fontSize="12px" as="b">
              {message}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
