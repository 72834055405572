import { useSelector } from "react-redux";
import { ReviewsTableRow } from "./ReviewsTableRow";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import { getReviews, rewiewsIsLoading } from "../store/Reviews.selectors";
import { TheadColumns } from "../constants";

const ReviewsRow = (review) => {
  const { id, text, mark, product, status, user } = review;
  return (
    <ReviewsTableRow
      productId={id}
      textReviev={text}
      productName={product.name}
      reviewMark={mark}
      moderationStatus={status}
      user={user}
    />
  );
};

export const ReviewsTable = ({ currentTab }) => {
  const reviewsItems = useSelector(getReviews());
  const isLoading = useSelector(rewiewsIsLoading());

  return (
    <TableConstructor Row={ReviewsRow} theadItems={TheadColumns} items={reviewsItems?.data} isLoading={isLoading} />
  );
};
