import camelCase from "lodash/camelCase";

const convertObjectKeysToCamelCase = (obj) => {
  const entries = Object.entries(obj);

  const result = entries.reduce((prev, currentValue) => {
    const [key, value] = currentValue;
    return { ...prev, [camelCase(key)]: value };
  }, {});

  return result;
};

export default convertObjectKeysToCamelCase;
