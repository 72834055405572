import { Box, Input } from "@chakra-ui/react";
import { FormControlWithError } from "../../../components/FormControlWithError/FormControlWithError";
import { PhonePushPreview } from "../Components/PhonePushPreview";

import { useFormikContext } from "formik";

export const NameAndTitleFormPart = ({ isFormBlockedForEditing }) => {
  const { values, errors, handleChange } = useFormikContext();

  return (
    <Box width="410px">
      <FormControlWithError title="Текст уведомления*" hasError={errors.message} errorText={errors.message}>
        <Input name="message" value={values.message} onChange={handleChange} disabled={isFormBlockedForEditing} />
      </FormControlWithError>

      <PhonePushPreview message={values.message} />
    </Box>
  );
};
