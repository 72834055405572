import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Flex, Input, Box, Tab, TabList, Tabs } from "@chakra-ui/react";
import { Pagination } from "../../components/Pagination";
import { useDebounceCallback } from "../../hooks/useDebounceCallback";
import { usePagination } from "../../hooks/usePagination";
import { getReviews, getSelectReview } from "./store/Reviews.selectors";
import { ReviewsTable } from "./ReviewsTable/ReviewsTable";
import { getReviewsThunk } from "./store/Reviews.thunk";
import { SELECTED_TAB_STYLES } from "../../common/constants";

import { useSearchParams } from "../../hooks/useSearchParams";
import { LoadingState } from "../../store/common";
import { REVIEW_MODERATION_STATUS_ITEM } from "./constants";

export const ReviewsPage = () => {
  const [querySearch, setQuerySearch] = useState("");
  const [idOfSearchingWarehouse, setIdOfSearchingWarehouse] = useState("");
  const dispatch = useDispatch();
  const BASE_DEBOUNCE_DELAY = 1000;
  const reviewsItems = useSelector(getReviews());
  const { loadingState: loadingStateChangeReviewStatus } = useSelector(getSelectReview());
  const { handleGetSearchFieldValue, handleChangeSearchParams } = useSearchParams();
  const getValueById = (id) => {
    return REVIEW_MODERATION_STATUS_ITEM.find((item) => item.id === id).value || "";
  };
  const {
    isPageValid,
    isPrevDisabled,
    isNextDisabled,
    page,
    limit,
    totalPages,
    nextPage,
    prevPage,
    setPage,
    setLimit,
  } = usePagination({
    total: reviewsItems?.count,
  });
  const currentTab = +handleGetSearchFieldValue("currentTab") ?? 0;

  const requestReview = async (query) => {
    dispatch(
      getReviewsThunk({
        limit,
        take: limit,
        skip: (page - 1) * limit,
        query: query ?? querySearch,
        ...(idOfSearchingWarehouse && { warehouseId: idOfSearchingWarehouse.id }),
        status: getValueById(currentTab),
      })
    ).unwrap();
  };

  useEffect(() => {
    requestReview();
  }, [limit, page, idOfSearchingWarehouse, currentTab]);

  useEffect(() => {
    if (loadingStateChangeReviewStatus === LoadingState.FULFILLED) requestReview();
  }, [loadingStateChangeReviewStatus]);

  const debounceRequest = useDebounceCallback(requestReview, BASE_DEBOUNCE_DELAY, []);

  const valueSetter = (value) => {
    debounceRequest(value);

    setQuerySearch(value);
  };

  return (
    <PageLayout>
      <Flex mb="35px" width="100%" justifyContent="end">
        <Box minWidth="70px" textAlign="start">
          <Input
            value={querySearch}
            onChange={(e) => valueSetter(e.target.value)}
            placeholder="Введите название товара"
          />
        </Box>
      </Flex>
      <Tabs
        defaultIndex={currentTab}
        tabIndex={currentTab}
        height="calc(100% - 75px)"
        w="100%"
        onChange={(tab) => handleChangeSearchParams({ currentTab: tab })}
      >
        <TabList borderBottom="none" marginBottom="8px">
          <Flex width="100%" justifyContent="space-between" flexDirection="row">
            <Flex color="#737680">
              <Tab _selected={SELECTED_TAB_STYLES}>Новые</Tab>
              <Tab _selected={SELECTED_TAB_STYLES}>Прошли модерацию</Tab>
              <Tab _selected={SELECTED_TAB_STYLES}>Скрытые</Tab>
            </Flex>

            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <ReviewsTable />
      </Tabs>
    </PageLayout>
  );
};
