import { useNavigate } from "react-router-dom";
import { Box, Flex, Td, Tr } from "@chakra-ui/react";
import { StarIcon } from "../../../assets/icon/StarIcon";
import { REVIEW_MODERATION_STATUS } from "../constants";

import { useDispatch } from "react-redux";
import { changeReviewStatusThunk } from "../store/Reviews.thunk";

import RejectIcon from "../../../assets/icon/RejectIcon";
import ApproveIcon from "../../../assets/icon/ApproveIcon";

export const ReviewsTableRow = ({ productId, textReviev, productName, reviewMark, moderationStatus, user }) => {
  const dispatch = useDispatch();

  const handleChangeStatus = (status) => {
    dispatch(changeReviewStatusThunk({ productId, status: { status } }));
  };
  return (
    <Tr key={productId}>
      <Td wordBreak="break-word" whiteSpace="normal">
        {productName}
      </Td>
      <Td wordBreak="break-word" whiteSpace="normal">
        {textReviev}
      </Td>
      <Td wordBreak="break-word" whiteSpace="normal">
        {user ? `${user?.name || ""} ${user?.lastName || ""}` : "Удалённый пользователь"}
      </Td>
      <Td>
        <Flex flexDirection="row" alignItems="center" gap="1">
          {reviewMark}
          <Flex flexDirection="row" alignItems="center" gap="1">
            {[...new Array(reviewMark)].map((_, i) => (
              <StarIcon key={i} isActive />
            ))}
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex alignItems="center">
          <Flex flexDirection="row" gap="2" margin="auto">
            <button type="button" onClick={() => handleChangeStatus(REVIEW_MODERATION_STATUS.APPROVED)}>
              <ApproveIcon isActive={REVIEW_MODERATION_STATUS.APPROVED === moderationStatus} />
            </button>
            <button type="button" onClick={() => handleChangeStatus(REVIEW_MODERATION_STATUS.REJECTED)}>
              <RejectIcon isActive={REVIEW_MODERATION_STATUS.REJECTED === moderationStatus} />
            </button>
          </Flex>
        </Flex>
      </Td>
    </Tr>
  );
};
