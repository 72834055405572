import { createAsyncThunk } from "@reduxjs/toolkit";

import { getReportWishlist } from "../../../services/analyticsService";

import { SLICE_NAME } from "./types";
import { generateFile } from "../../../utils/generateFile";
import { editReviewStatus, getOneReview, getReviews } from "../../../services/reviewsService";

export const getReviewsThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReviews`,
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const reviews = await getReviews(params);
      return reviews.data;
    } catch (err) {
      console.log(err);
      if (!err?.status) {
        return fulfillWithValue([]);
      }
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
export const changeReviewStatusThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchChangeReviewStatus`,
  async ({ productId, status }, { rejectWithValue, fulfillWithValue }) => {
    try {
      const review = await editReviewStatus(productId, status);
      return review.data;
    } catch (err) {
      console.log(err);
      if (!err?.status) {
        return fulfillWithValue([]);
      }
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
export const getOneReviewThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetOneReview`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const review = await getOneReview(id);
      return review.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const createReportWishListThunk = createAsyncThunk(
  `${SLICE_NAME}/createReportWishList`,
  async (params, { rejectWithValue }) => {
    try {
      const result = await getReportWishlist(params);

      generateFile(result.data.data);

      return result.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
